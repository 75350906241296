<template>
    <div class="user-index">
        <div class="main-h1">Подать документы</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Номер дела</div>
                <div class="row">
                    <div class="col-3">
                        <input v-model="title"/>
                    </div>
                    <div class="col-3">
                        <div class="btn blue" @click="findClaim">Найти</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "edit",
    data() {
        return {
            title: '',
            claim: null,
            claim_id: {},
        }
    },
    methods: {
        findClaim() {
            this.$api.post(`claim/find`, {title: this.title}).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$router.push(`/panel/claim/${res.data.data.id}/edit_claimant`);
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
    },
    created() {

    },
    watch: {}

}
</script>

<style scoped>

</style>